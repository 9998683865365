import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Logomark from '../SVG/Logomark'
import RightChevron from '../SVG/RightChevron'

const Hero = ({ lead, body, list, listHeading, imageList }) => {
  return (
    <div className="lg:flex md:flex-wrap lg:mb-20 lg:-ml-12">
      <div
        className={`w-full lg:pl-12 pb-12 lg:pb-0 lg:pt-8
          ${list.length > 0 ? 'lg:w-6/12' : 'lg:w-5/12 xl:w-4/12'}`}
      >
        {lead && (
          <h3 className="text-xl lg:text-3xl leading-tight mb-6 font-bold">
            {lead}
          </h3>
        )}
        {body.html && (
          <div
            className="rte mb-8"
            dangerouslySetInnerHTML={{ __html: body.html }}
          />
        )}
        <Link
          className="button button--blue py-5 px-6 border inline-block"
          to="/contact"
        >
          <div className="button__inner font-semibold">
            <span className="inline-block">Enquire About This Service</span>
            <RightChevron className="ml-8" />
          </div>
        </Link>
      </div>
      <div className="hidden lg:block lg:w-1/12 lg:pl-12 lg:pt-8"></div>
      <div
        className={`${
          list.length > 0 ? 'lg:w-5/12' : 'lg:w-6/12 xl:w-7/12'
        } lg:pl-12`}
      >
        <div className="sticky top-0 left-0 pt-8 mb-8 lg:-mb-5">
          {listHeading && (
            <h3 className="text-xl lg:text-2xl font-bold leading-tight mb-8">
              {listHeading}
            </h3>
          )}
          {list && (
            <ul className="font-semibold lg:text-lg">
              {list
                .filter(item => item.list_item !== null)
                .map((item, index) => {
                  return (
                    <li key={index} className="pb-5 flex items-start">
                      <Logomark className="w-4 lg:w-5 mr-4 lg:mr-6 flex-shrink-0" />
                      {item.list_item}
                    </li>
                  )
                })}
            </ul>
          )}
          {imageList && (
            <>
              {imageList
                .filter(item => item.image !== null)
                .map((item, index) => {
                  const fluidImage =
                    item.image.localFile && item.image.localFile.childImageSharp
                      ? item.image.localFile.childImageSharp.fluid
                      : null

                  if (
                    !item.image_heading &&
                    !item.image_subheading &&
                    !item.image_body.html
                  ) {
                    return null
                  }

                  return (
                    <div
                      key={index}
                      className="flex flex-col sm:flex-row border border-blue-10 mb-7"
                    >
                      <div
                        className="w-full sm:w-2/5 p-4 flex items-center justify-center"
                        style={{ aspectRatio: '3/2' }}
                      >
                        {fluidImage && (
                          <Img
                            className="w-full"
                            fluid={fluidImage}
                            alt={item.image.alt}
                          />
                        )}
                      </div>
                      <div className="w-full lg:w-3/5 p-6 bg-blue-10">
                        {item.image_heading && (
                          <h3 className="text-xl xl:text-2xl font-bold leading-tight">
                            {item.image_heading}
                          </h3>
                        )}
                        {item.image_subheading && (
                          <h4 className="text-lg mt-3 font-medium">
                            {item.image_subheading}
                          </h4>
                        )}
                        {item.image_body.html && (
                          <div
                            className="rte mt-8 font-semibold"
                            dangerouslySetInnerHTML={{
                              __html: item.image_body.html,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Hero
