import React from 'react'

const Content = ({ content }) => {
  const result = content.filter(
    item => item.content_heading !== null && item.content_body.html !== ''
  )

  if (result.length === 0) return null
  const invertSectionData = content.map(item => item.heading_position)

  return (
    <div className="lg:text-lg">
      {result.map((item, index) => {
        const isInverted = invertSectionData[index] === 'Right'
        const headingSize = item.heading_size
        const background = item.background_colour
        const headingClass =
          headingSize === 'Larger'
            ? `heading-2xl ${background !== 'Blue' ? 'lg:w-2/3' : ''}`
            : `heading-xl ${background !== 'Blue' ? 'lg:w-1/2' : ''}`

        return (
          <div
            key={index}
            className={`pb-16 lg:pb-24 ${background === 'Blue' ? 'pt-8' : ''}`}
          >
            <div
              className={`lg:flex ${isInverted ? 'lg:flex-row-reverse' : ''} ${
                background === 'Blue' ? 'bg-blue text-white p-8 lg:pl-0' : ''
              }`}
            >
              <div
                className={`w-full lg:pl-12 ${
                  isInverted ? 'lg:w-5/12  ' : 'lg:w-6/12'
                } ${background === 'Blue' ? '' : 'lg:pt-8'}`}
              >
                <h3
                  className={`block ${headingClass} mb-8 lg:mb-0 ${
                    background === 'Blue' && !isInverted ? 'lg:-ml-4' : ''
                  }`}
                >
                  {item.content_heading}
                </h3>
              </div>
              <div className="hidden lg:block lg:w-1/12 pl-12 pt-8"></div>
              <div
                className={`lg:pl-12 ${
                  isInverted ? 'lg:w-6/12' : 'lg:w-5/12'
                } ${background === 'Blue' ? '' : 'lg:pt-8'}`}
              >
                <div
                  className={`rte font-medium ${
                    background === 'Blue' && isInverted ? 'lg:-ml-4' : ''
                  }`}
                  dangerouslySetInnerHTML={{ __html: item.content_body.html }}
                />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Content
